<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs6 md6>
                  <v-text-field
                    :rules="campoRules"
                    placeholder=" "
                    outline
                    label="Nome"
                    id="nome"
                    name="nome"
                    type="text"
                    required
                    v-model="usuario.nome"
                    @input="$event => usuario.nome = $event.toUpperCase()"
                  ></v-text-field>
                </v-flex>

                <v-flex xs3 md3>
                  <v-text-field
                    placeholder=" "
                    outline
                    label="Matrícula"
                    id="matricula"
                    name="matricula"
                    type="text"
                    v-model="usuario.matricula"
                    @input="$event => usuario.matricula = $event.toUpperCase()"
                  ></v-text-field>
                </v-flex>

                <v-flex xs3 md3>
                  <v-currency-field
                    :disabled="unidade.tipo === 'C'"
                    placeholder=" "
                    outline
                    label="Comissão(%)"
                    id="comissao"
                    name="comissao"
                    :max="unidade.comissao"
                    :min="0"
                    v-model="usuario.comissao"
                  ></v-currency-field>
                </v-flex>

                <v-flex xs6 md6>
                  <v-text-field
                    :rules="emailRules"
                    placeholder=" "
                    outline
                    label="E-mail"
                    id="email"
                    name="email"
                    type="email"
                    required
                    v-model="usuario.email"
                    @input="$event => usuario.email = $event.toLowerCase()"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 md6>
                  <v-text-field
                    :disabled="!!usuario.codigo"
                    :rules="campoRules"
                    placeholder=" "
                    outline
                    label="Senha"
                    id="senha"
                    name="senha"
                    type="password"
                    required
                    v-model="usuario.senha"
                  ></v-text-field>
                </v-flex>

                <v-flex xs11 md11 v-if="unidade.tipo === 'L'">
                  <v-switch
                    v-model="usuario.vendedor"
                    :label="usuario.vendedor ? 'Vendedor' : 'Loja'"
                    color="green"
                    placeholder=" "
                    outline
                  ></v-switch>
                </v-flex>

                <v-flex xs1 md1 v-if="!!usuario.codigo">
                  <v-switch
                    v-model="usuario.ativo"
                    :label="usuario.ativo ? 'Ativo' : 'Desativado'"
                    color="green"
                  ></v-switch>
                </v-flex>
                <v-toolbar flat class="transparent">
                  <v-spacer></v-spacer>
                  <v-btn color="green" class="white--text" @click="saveUsuario(usuario)">Gravar</v-btn>
                </v-toolbar>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
const unidadeModulo = () => import('./../../services/unidade')
const usuarioModulo = () => import('./../../services/usuario')

export default {
  name: 'UsuarioCad',
  data () {
    return {
      valid: true,
      unidade: {},
      usuario: {
        codigo: null,
        unidade_codigo: null,
        nome: null,
        matricula: null,
        email: null,
        senha: null,
        comissao: 0,
        tipo: null,
        ativo: true,
        vendedor: false
      },
      emailRules: [
        v => !!v || 'E-mail é obrigatório.',
        v => /.+@.+/.test(v) || 'E-mail não é válido.'
      ],
      campoRules: [
        v => !!v || 'Campo é obrigatório.',
      ],
    }
  },
  mounted () {
    this.getUnidade()
    this.getUsuario()
  },
  methods: {
    async voltar () {
      this.$router.push(`/usuario/${this.$route.params.unidade}`)
    },
    async getUnidade () {
      if (this.$route.params.unidade) {
        let unidadeDecode = atob(this.$route.params.unidade)
        this.usuario.unidade_codigo = unidadeDecode
        let unidadeService = await unidadeModulo()
        let resp = await unidadeService.getByCodigo(this.$axios, unidadeDecode)
        if (resp.status === 200 && resp.data) {
          this.unidade = resp.data
          this.usuario.tipo = this.unidade.tipo
        }
      }
    },
    async getUsuario () {
      if (this.$route.params.id) {
        // let usuarioDecode = atob(this.$route.params.id)
        let usuarioService = await usuarioModulo()
        let resp = await usuarioService.getByCodigo(this.$axios, this.$route.params.id)
        if (resp.status === 200 && resp.data) {
          this.usuario.codigo = resp.data.codigo
          this.usuario.unidade_codigo = resp.data.unidade_codigo
          this.usuario.nome = resp.data.nome
          this.usuario.matricula = resp.data.matricula
          this.usuario.email = resp.data.email
          this.usuario.senha = resp.data.senha
          this.usuario.comissao = resp.data.comissao
          this.usuario.tipo = resp.data.tipo
          this.usuario.ativo = resp.data.ativo
          if (resp.data.tipo === 'V') {
            this.usuario.vendedor = true
          }
        }
      }
    },
    async saveUsuario (usuario) {
      if (usuario.codigo) {
        let usuarioService = await usuarioModulo()
        let resp = await usuarioService.countUsuarioByEmailUpdate(this.$axios, { email: usuario.email, codigo: usuario.codigo })
        if (resp.status === 200 && Number(resp.data.qtd) === 0) {
          await this.editUsuario(usuario)
        } else {
          this.$swal('E-mail usado por outro usuário.', '', 'error')
        }
      } else {
        let usuarioService = await usuarioModulo()
        let resp = await usuarioService.countUsuarioByEmail(this.$axios, { email: usuario.email })
        if (resp.status === 200 && Number(resp.data.qtd) === 0) {
          await this.addUsuario(usuario)
        } else {
          this.$swal('E-mail usado por outro usuário.', '', 'error')
        }
      }
    },
    async addUsuario (usuario) {
      try {
        if (!this.$refs.form.validate()) {
          return
        }
        if (usuario.vendedor) {
          usuario.tipo = 'V'
        }
        let usuarioService = await usuarioModulo()
        let resp = await usuarioService.addUser(this.$axios, { ...usuario })
        if (resp.status === 200 && resp.data.success) {
          this.$toast.success('Sucesso ao incluir o registro')
          await this.voltar()
        } else {
          this.$swal('Não foi possível inserir o registro.', '', 'error')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async editUsuario (usuario) {
      try {
        if (!this.$refs.form.validate()) {
          return
        }
        if (usuario.vendedor) {
          usuario.tipo = 'V'
        }
        let usuarioService = await usuarioModulo()
        let resp = await usuarioService.editUser(this.$axios, { ...usuario })
        if (resp.status === 200 && resp.data.success) {
          this.$toast.success('Sucesso ao editar o registro')
          await this.voltar()
        } else {
          this.$swal('Não foi possível editar o registro.', '', 'error')
        }
      } catch (error) {
        console.log(error)
      }
    },
  }
}
</script>